export const WeatherURL = "Weather_Url";
export const EmailServiceURL = "Email_Service_Url";
export const TRIPADVISORURL =
  "https://api.tripadvisor.com/api/partner/2.0/location/";

export const GOOGLE_MAP_DIR_URL = "https://www.google.com/maps/dir/?api=1&";
export const COOKIE_KEYS = {
  cookieConsent: "cookieConsent",
};
export const UNDER_MAINTENANCE = "false";

export const REDIRECTS = [
    {from: "ln", to: "en" },
    {from: "tour-booking/private-celebrations", to: "tour-booking" },
    {from: "tour-booking/sunset-tour", to: "tour-booking"},
    {from: "offers/golden-diwali", to: "tours-details" },
    {from: "tour-booking/golden-diwali", to: "tour-booking" },
    // {from: "offers/easter-at-inside-burj-al-arab", to: "tours-details" },
    {from: "booking/tour-booking/", to: "tour-booking"},
    {from: "contact/press-centre/press-releases/press-releases-for-2021/andy-nicholson-gm-announcement", to: "contact/press-centre/press-releases/press-releases-for-2021/" }

];

export const HOTELS_LIST = [
  { id: "JAN", title: "Jumeirah Al Naseem", room_no_prefix: 17 },
  { id: "JAQ", title: "Jumeirah Al Qasr", room_no_prefix: 16 },
  { id: "BAA", title: "Burj Al Arab Jumeirah", room_no_prefix: 12 },
  { id: "JBH", title: "Jumeirah Beach Hotel", room_no_prefix: 13 },
  { id: "DAM", title: "Jumeirah Dar Al Masyaf", room_no_prefix: 11 },
  { id: "MAS", title: "Jumeirah Mina A Salam", room_no_prefix: 14 },
  { id: "JZS", title: "Jumeirah Zabeel Saray", room_no_prefix: 21 },
  { id: "JCH", title: "Jumeirah Creekside Hotel", room_no_prefix: 23 },
  { id: "JET", title: "Jumeirah Emirates Towers", room_no_prefix: 11 },
  { id: "JMG", title: "Jumeirah Living Marina Gate", room_no_prefix: 31 },

  {
    id: "JLWTC",
    title: "Jumeirah Living World Trade Centre Residence",
    room_no_prefix: 19,
  },
  {
    id: "JSI",
    title: "Jumeirah at Saadiyat Island Resort",
    room_no_prefix: 20,
  },
  { id: "ZHTG", title: "Zabeel House The Greens", room_no_prefix: 30 },
];

export const AVAILABLE_QUANTITIES = [
    { productCode: "IBAAFAMSDEIA", maxquantity: 1 }    
];

export const PRODUCT_AVAILABLEDATES = [
    {
        productCode: "IBAAFAMBPB2C", availableStartDate: "12/14/2022", availableEndDate:"12/24/2022"
    }    
];

export const allTimeslots = [
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
];

export const bookingCategories = [
    "guided-tour"
];

export const PRODUCTCODE_LIST = {
  generalAdmission: "TRGEN",
  kidsGoFree: "OFKIDSFREE",
  photoMoment: "ADDONPHM",
  kidsClub: "ADDONKCC",
  santa:"IBAAFAMSDEIA"
};

export const PRODUCTCODE_TO_PRODUCTNAME = {
  ADDONPHM: "Iconic Photo Moment",
  ADDONKCC: "Kids Club",
  IBAAAOHB: "Add on Soft Beverage",
  IBAAADRSW: "Addon Retails Souvenir",
  IBAAUBB2C: "Add on Signature Beverage",
  IBAAFAMSDEIA: "Iconic Photo Moment Santa Addon",
  IBAAUPBB2C: "Add on Pizza/Sandwich to tour",
  IBAAMGP2BRUMAAO: "Addon UMABar Combo-1 Margherita Pizza with 2 Beers"
};

export const TOURTYPEID = {
  tours: "e5341557-14a1-4fc3-8d23-70a866e164e5",
  toursanddrinks: "dd81437c-e7c6-4c80-9476-40fb9d2e93ff",
  diningandtour: "7f243956-de43-4e87-b97d-b40822a9c904",
  family: "0711e177-dd0a-4c05-a0b3-0e514fc2eaf6",
  golden: "4e32e3dd-ece8-4c4f-9516-a8e6e24df0c1",
  sunset: "d5175c10-a381-47b2-8852-075a918e0f28",
  specialevents:"a1fee81d-22a7-40f6-9c07-00a850bc22df",
  afternoon: "df210a61-feb3-482f-933d-29bd77634f37",
  fazaa: "9c07ec5c-e0b8-4bc6-983a-5b256b92c56f",
  esaad: "c14e11e2-9241-47ac-825b-e9bb691f7f57",
  emiratesplatinum: "5dbf3d88-9917-4810-9eab-e4a94602e2b4",
  apc: "257b89c9-237c-4272-8187-a8dea77f7230"
};

export const paymentStyles = {
  main: {
    height: 260,
  } /* the style for the main wrapper div around the card input*/,
  input: {
    background: "none",
    color: "#7C6F55",
    fontSize: "18px",
    padding: "10px 10px 10px 5px",
    display: "block",
    // width: "90%",
    borderRadius: 0,
    borderBottom: "1px solid #7C6F55 !important",
    borderStyle: "solid !important",
    borderColor: "#7C6F55 !important",
    fontUrl: "https://fonts.googleapis.com/css?family=Raleway&display=swap",
    fontFamily: "Raleway !important",
  } /* custom style options for the input fields */,
  invalid: {
    background: "none",
    color: "#7C6F55",
    fontSize: "18px",
    padding: "10px 10px 10px 5px",
    display: "block",
    // width: "90%",
    borderRadius: 0,
    borderBottom: "1px solid red !important",
    borderStyle: "solid !important",
    borderColor: "red !important",
    fontUrl: "https://fonts.googleapis.com/css?family=Raleway&display=swap",
    fontFamily: "Raleway !important",
  } /* custom input invalid styles */,
};
